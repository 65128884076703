body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* App.css */

.App {
  text-align: center;
}

.App-logo {
  width: 200px;
}

.App-header {
  font-size: calc(5px + 2vmin);
}

h1, h2, h3, h4, h5 {
  font-weight: 300;
}

.search-bar-container {
  flex: 2 1;
  text-align: center;
  position: relative;
}

.search-bar-container input[type="text"] {
  padding: 10px;
}

.permanentActions,
.routeViews {
  border-radius: 24px;
}

.resultList {
  width: 92%;
  text-align: left;
  position: absolute;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  padding: 10px;
}

.App-link {
  color: #61dafb;
}

span[type="C"] {
  background-color: blue;
  color: white;
  padding: 1px 10px;
  border-radius: 1rem;
  margin-left: 1rem;
}

span[type="P"] {
  background-color: green;
  color: white;
  padding: 1px 10px;
  border-radius: 1rem;
  margin-left: 1rem;
}

span.clientName {
  background: blue;
  padding: 1px 10px;
  border-radius: 1rem;
  margin-left: 1rem;
}

.client-detail-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.CompanyTopContainer {
  display: flex;
}

.CompanyDetails,
.CompanyActions {
  background-color: #dddddd;
  padding: 10px;
  width: 50%;
}

.CompanyActions {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.CompanyProjects {
  background-color: lightblue;
  border-radius: 5px;
  padding: 10px;
  flex: 2 1;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.CompanyQuotations {
  background-color: lightcoral;
  border-radius: 5px;
  padding: 10px;
  flex: 2 1;
}

.CompanyInvoices {
  background-color: lightgreen;
  border-radius: 5px;
  padding: 10px;
  flex: 2 1;
}

.CompanyProjects,
.CompanyInvoices,
.CompanyQuotations {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
    flex: 1 1; 
    padding: 10px;
}

.projects-invoices-quotations-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

ul.searchResultList {
  width: 100%;
  list-style: none;
  text-align: left;
  color: black;
  padding: 24px;
  border-radius: 24px;
  position: absolute;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
  z-index: 9;
  border: 1px solid #e7e5e5;
}

.search-bar-container input[type="text"] {
  font-size: 20px;
  width: 100%;
}

.capitalize {
  text-transform: capitalize;
}

a.permanentActionButton {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

.logWidget {
  display: flex;
  flex-direction: column !important;
  grid-gap: 16px;
  gap: 16px;
}

.permanentActionButton.active {
  background-color: #f0f0f0; /* or any other styling you prefer */
}

.logWidget {
  border-radius: 24px;
}

.logWidget .sliderContainer {
  padding: 0px 16px;
  border-radius: 32px;
} 

.edit-modal {
  position: absolute;
  width: 400px;
  background-color: #717171;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  outline: none;
  border-radius: 32px;
}

.edit-modal form {
  display: flex;
  flex-direction: column;
}

.edit-modal form label {
  display: flex;
  justify-content: space-between;
}

.edit-modal form button {
  margin-top: 1rem;
}
